<template>
  <div class="plan-wrapper">
    <div class="plan-info">
      <div class="d-flex flex-column">
        <AppText color="var(--color-939393)" variant="div" mb="9px">
          Current plan
        </AppText>
        <AppText class="font-medium is-first-letter-capitalized">
          {{ currentPlan }}
        </AppText>
      </div>
      <div class="d-flex flex-column">
        <AppText color="var(--color-939393)" variant="div" mb="9px">
          Cycle
        </AppText>
        <AppText class="font-medium is-first-letter-capitalized">
          {{ currentCycle }}
        </AppText>
      </div>
      <div class="d-flex flex-column">
        <AppText color="var(--color-939393)" variant="div" mb="9px">
          Renewal Date
        </AppText>
        <DateFormatter class="font-medium" :date="info?.renewalDate" :format="dateFormat" />
      </div>
      <div class="d-flex flex-column">
        <AppText color="var(--color-939393)" variant="div" mb="9px">
          Balance
        </AppText>
        <CurrencyFormatter class="font-medium" :value="info?.balance" is-account-currency />
      </div>
    </div>
  </div>

  <div class="position-relative full-height w-100" style="padding:24px 40px;">
    <Transition name="show">
      <div v-if="!wasSuccessfullyUpdated" class="full-height">
        <AppText size="15px" class="font-medium">
          Set new plan
        </AppText>

        <TabsContainer
          v-model="plan"
          :options="tabs"
          type="card"
          style="margin-top: 20px;"
          :has-min-height="false"
        >
          <template #title="{ item }">
            <div class="d-flex w-100 align-items-center">
              <AppIcon :name="`plan-${item.value?.toLowerCase()}`" size="24px" is-img-tag />
              <AppText variant="div" ml="10px" color="black" class="font-medium">
                {{ item.text }}
              </AppText>
            </div>
          </template>

          <div style="padding: 27px 24px;">
            <div class="d-flex align-items-center" style="margin-bottom: 20px;">
              <AppText variant="div" style="width: 140px;">
                Cycle
              </AppText>
              <FRadioButton
                v-slot="option"
                v-model="selectedCycle"
                is-animation-disabled
                :options="cycleOptions"
              >
                {{ option.text }}
              </FRadioButton>
            </div>

            <div class="d-flex align-items-center" style="height: 44px;">
              <AppText variant="div" style="width: 140px;">
                {{ isLifetimePlan ? 'Charge' : 'Renewal in' }}
              </AppText>
              <template v-if="!isLifetimePlan">
                <InputNumberStep v-model="renewalIn" no-label :max="selectedCycle === 'MONTHLY' ? 6 : 3">
                  <AppText class="font-medium" pl="10px" pr="10px">
                    {{ renewalIn }} {{ cyclePreffix }}
                  </AppText>
                </InputNumberStep>

                <AppText pr="8px" pl="20px">
                  on
                </AppText>
                <DateFormatter color="var(--color-939393)" :format="dateFormat" :date="nextRenewal" />
              </template>
              <FInput v-else v-model="charge" style="width: 256px;" mask="onlyInteger">
                <template #prefix>
                  <AppText size="17">
                    $
                  </AppText>
                </template>
              </FInput>
            </div>
          </div>
        </TabsContainer>

        <FSpacer />

        <div class="d-flex" style="margin-top: 28px;">
          <FButton size="small" @click="onChangePlan">
            Modify plan and issue invoice
          </FButton>
          <FButton size="small" type="text" @click="onReset">
            Reset
          </FButton>
        </div>
      </div>

      <div v-else>
        <div class="flex-center flex-column" style="padding-top: 36px;">
          <CheckIcon style="margin-bottom: 20px;" />
          <AppText size="15px" class="font-medium">
            Plan successfully updated
          </AppText>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import dayjs from 'dayjs';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import InputNumberStep from '@/components/Inputs/InputNumberStep.vue';
import TabsContainer from '@/components/Containers/TabsContainer.vue';
import CheckIcon from '@/components/Icon/CheckIcon.vue';

import { APP_ROLES, cycleSelectOptions } from '@/common/data';
import { useAdmin } from '@/composables/useAdmin';
import { useBus } from '@/composables/useBus';
import { useToken } from '@/composables/useToken';

import { changePlan } from './api';

export default defineComponent({
  components: {
    InputNumberStep,
    TabsContainer,
    DateFormatter,
    CurrencyFormatter,
    CheckIcon,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    merchantId: {
      type: String,
      default: '',
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const { tokenRole } = useToken();
    const currentPlan = computed(() => props.info?.plan);
    const currentCycle = computed(() => props.info?.cycle);

    const tabs = [
      { text: 'Pro', value: 'PRO' },
      { text: 'Business', value: 'BUSINESS' },
      { text: 'Black', value: 'BLACK' },
    ];
    const plan = ref(tabs[0].value);

    const cycleOptions = computed(() => [
      ...cycleSelectOptions,
      { text: 'Lifetime', value: 'LIFETIME' },
    ].filter((el) => {
      if (plan.value === 'BLACK' && tokenRole.value === APP_ROLES.SUPER_ADMIN) {
        return el;
      }

      if (el.value === 'LIFETIME') {
        return null;
      }

      if (plan.value === 'PRO' && el.value === 'MONTHLY') {
        return null;
      }

      return el;
    }).filter((el) => el));

    const selectedCycle = ref(cycleOptions.value[0].value);
    const renewalIn = ref(1);
    const charge = ref(0);

    const cyclePreffix = computed(() => {
      if (selectedCycle.value === 'YEARLY') {
        return `Year${renewalIn.value > 1 ? 's' : ''}`;
      }
      return `Month${renewalIn.value > 1 ? 's' : ''}`;
    });

    const isLifetimePlan = computed(() => selectedCycle.value === 'LIFETIME');

    const nextRenewal = computed(() => dayjs().add(renewalIn.value, selectedCycle.value === 'MONTHLY' ? 'month' : 'year').format());

    watch(plan, () => {
      selectedCycle.value = cycleOptions.value[0].value;
    });

    watch(selectedCycle, () => {
      renewalIn.value = 1;
    });

    const wasSuccessfullyUpdated = ref(false);
    const onChangePlan = async () => {
      const { isSuccess } = await changePlan(props.merchantId, {
        plan: plan.value,
        cycle: selectedCycle.value,
        term: renewalIn.value,
        charge: Number(charge.value),
      });

      if (isSuccess) {
        wasSuccessfullyUpdated.value = true;
        emit('success');
        charge.value = 0;
      }
    };

    const { emitHandler } = useBus();
    emitHandler('update-table-data', () => {
      wasSuccessfullyUpdated.value = false;
    });

    const onReset = () => {
      selectedCycle.value = props.info.cycle;
      plan.value = props.info.plan;
      renewalIn.value = 1;
      charge.value = 0;
    };

    const { dateFormat } = useAdmin();

    return {
      currentPlan,
      currentCycle,

      tabs,
      plan,

      cycleOptions,
      selectedCycle,
      cyclePreffix,
      isLifetimePlan,

      renewalIn,
      nextRenewal,
      dateFormat,
      charge,

      wasSuccessfullyUpdated,

      onChangePlan,
      onReset,
    };
  },
});
</script>

<style scoped lang="scss">
.plan-wrapper {
  padding: 20px 40px;
  background: var(--color-F7F7F7);
}
.plan-info {
  display: flex;
  gap: 44px;
}
:deep(.el-input__inner) {
  font-size: 17px !important;
}
</style>
