import { ref } from 'vue';

import { useFetch } from '@/api';

export const merchantApi = () => {
  const isLoading = ref(false);

  const inviteMerchant = async (params) => {
    const { axiosWrapper, successHandler } = useFetch();
    isLoading.value = true;
    const { isSuccess, errorObject } = await axiosWrapper({
      type: 'post',
      url: '/merchant-admin/invitation/new',
      params,
    });

    if (isSuccess) {
      successHandler('Merchant has been invited');
    }

    setTimeout(() => {
      isLoading.value = false;
    }, 400);

    return { isSuccess, errorObject };
  };

  return {
    inviteMerchant,
    isLoading,
  };
};
