<template>
  <FButton
    type="plain"
    size="small"
    class="suspend-button"
    :class="{ success: type === 'success' }"
  >
    <div class="d-flex align-items-center">
      <AppIcon name="ban-block-slash" size="20px" class="mr-3" />
      <AppText class="mr-2 font-medium">
        {{ label }}
      </AppText>
    </div>
  </FButton>
</template>

<script>
export default {
  name: 'SuspendButton',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.suspend-button {
  &:hover {
    background: var(--color-error-01);

    :deep(.text) {
      color: var(--color-error);
    }
    :deep(.icon path) {
      fill: var(--color-error);
    }
  }

  &.success {
    background-color: rgb(80, 166, 33, 0.1);

    :deep(.text) {
      color: var(--color-green);
    }
    :deep(.icon path) {
      fill: var(--color-green);
    }

    &:hover {
      background-color: rgb(80, 166, 33, 0.1);
    }
  }
}
</style>
