<template>
  <div v-if="tokenRole === 'superadmin'">
    <SuspendButton v-if="status === 'ACTIVE'" label="Suspend" @click="$emit('suspend')" />
    <SuspendButton v-else label="Unsuspend" type="success" @click="$emit('unsuspend')" />
  </div>
  <SuspendVote v-else :vote-info="info?.vote" :status="status" :merchant-id="merchantId" />
</template>

<script>
import { defineComponent } from 'vue';

import { useToken } from '@/composables/useToken';

import SuspendButton from './SuspendButton.vue';
import SuspendVote from './SuspendVote.vue';

export default defineComponent({
  name: 'MerchantItemActions',
  components: {
    SuspendButton,
    SuspendVote,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default: () => {},
    },
    merchantId: {
      type: String,
      default: '',
    },
  },
  emits: ['suspend', 'unsuspend'],
  setup() {
    const { tokenRole } = useToken();

    return {
      tokenRole,
    };
  },
});
</script>
