import { useFetch } from '@/api';

export const getMerchantInfo = async (merchantId) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `/merchant-admin/merchants/${merchantId}`,
    params: { isFreshResponse: true },
  });

  return { isSuccess, data };
};

export const getHistory = async (merchantId) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `/merchant-admin/merchants/history/${merchantId}`,
    params: { isFreshResponse: true },
  });

  return { isSuccess, data };
};

export const changeLimits = async ({ merchantId, data }) => {
  const { axiosWrapper, successHandler } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/change-limits/${merchantId}`,
    params: data,
  });

  if (isSuccess) {
    successHandler('Limits has been updated');
  }

  return { isSuccess };
};

export const suspendMerchant = async ({ merchantId }) => {
  const { axiosWrapper, successHandler } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/suspend/${merchantId}`,
  });

  if (isSuccess) {
    successHandler('Merchant has been suspended');
  }

  return { isSuccess };
};

export const unsuspendMerchant = async ({ merchantId }) => {
  const { axiosWrapper, successHandler } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/unSuspend/${merchantId}`,
  });

  if (isSuccess) {
    successHandler('Merchant has been unsuspended');
  }

  return { isSuccess };
};

export const voteSuspendMerchantStart = async ({ merchantId, type }) => {
  const { axiosWrapper, successHandler } = useFetch();

  const { isSuccess, data } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/start-vote/${merchantId}`,
    params: { type },
  });

  if (isSuccess) {
    successHandler('Vote has been started');
  }

  return { isSuccess, data };
};

export const voteSuspendMerchant = async ({ merchantId, type, agree }) => {
  const { axiosWrapper } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/vote/${merchantId}`,
    params: { type, agree },
  });

  return { isSuccess };
};

export const changePlan = async (merchantId, params) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/change-plan/${merchantId}`,
    params,
  });

  return { isSuccess };
};

export const creditMerchant = async (merchantId, params) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchant-admin/merchants/credit/${merchantId}`,
    params,
  });

  return { isSuccess };
};
