import { reactive, ref } from 'vue';

import { TABLE_TYPES, statusCellOptions } from '@/common/tableTypes';
import { planSelectOptions, cycleSelectOptions } from '@/common/data';

export const commonColumns = [
  {
    name: 'uid',
    isShow: true,
    width: 100,
    isPermanent: true,
  },
  {
    name: 'companyName',
    isShow: true,
    width: 350,
    isPermanent: true,
  },
  {
    name: 'baseCurrency',
    isShow: true,
    width: 140,
    isPermanent: true,
  },
  {
    name: 'email',
    width: 250,
    isShow: false,
  },
  {
    title: 'Pruned On',
    name: 'prunedOn',
    type: TABLE_TYPES.DATE_TIME,
    isShow: true,
    isPermanent: true,
  },
];

export const columns = [
  ...commonColumns,
  {
    name: 'createdAt',
    type: TABLE_TYPES.DATE,
    isShow: true,
    width: 240,
    isPermanent: true,
  },
  {
    name: 'deletedAt',
    type: TABLE_TYPES.DATE,
    width: 240,
    isShow: false,
  },
  {
    name: 'plan',
    isShow: true,
    type: TABLE_TYPES.PLAN,
    width: 200,
    isPermanent: true,
  },
  {
    name: 'cycle',
    isShow: true,
    type: TABLE_TYPES.CYCLE,
    width: 200,
    isPermanent: true,
  },
  {
    ...statusCellOptions,
    isPermanent: true,
  },
];

export const prunedColumns = commonColumns;

export const filters = reactive([
  {
    label: 'Date Range',
    type: 'DATE_RANGE',
    field: ['dateFrom', 'dateTo'],
    modelValue: ref([]),
  },
  {
    label: 'Select Plan',
    field: 'plan',
    options: planSelectOptions,
    hasItemsIcon: true,
    type: 'SELECT',
    modelValue: ref(null),
  },
  {
    label: 'Select Cycle',
    field: 'cycle',
    options: [
      ...cycleSelectOptions,
      { text: 'Lifetime', value: 'LIFETIME' },
    ],
    hidePrefix: true,
    type: 'SELECT',
    modelValue: ref(null),
  },
]);

export const statuses = [
  { text: 'All', value: 'ALL' },
  { text: 'Active', value: 'ACTIVE' },
  { text: 'Inactive', value: 'INACTIVE' },
  { text: 'Suspended', value: 'SUSPENDED' },
  { text: 'Deleted', value: 'DELETED' },
];
