<template>
  <div>
    <ItemHeader :item="item" :is-loading="isMerchantInfoLoading" :info="selectedMerchant" @update="onRefresh" />
    <TabsContainer
      :key="item.id"
      v-model="currentTab"
      :options="tabs"
      :min-height="['INACTIVE', 'DELETED'].includes(status) ? '440px' : '504px'"
      has-full-height
    >
      <div class="full-height">
        <MerchantDetails
          v-if="currentTab === tabs[0].value"
          :preloaded-info="item"
          :info="selectedMerchant"
          :merchant-id="item.id"
          :is-loading="isMerchantInfoLoading"
          @success="updateMerchantSettings"
        />
        <HistoryBlock
          v-if="currentTab === tabs[1].value"
          :data="historyInfo"
          :date-format="dateTimeFormat"
          style="max-height: 504px;"
        />
        <MerchantModify
          v-if="currentTab === tabs[2]?.value"
          :info="selectedMerchant"
          :merchant-id="item.id"
          @success="updateMerchantSettings"
        />
        <AddCredit
          v-if="currentTab === tabs[3]?.value"
          :info="selectedMerchant"
          :merchant-id="item.id"
          @success="updateMerchantSettings"
        />
        <KeepAlive>
          <MerchantSettings
            v-if="currentTab === tabs[4]?.value"
            :info="selectedMerchant"
            :merchant-id="item.id"
            @success="updateMerchantSettings"
          />
        </KeepAlive>
      </div>
    </TabsContainer>
  </div>
</template>

<script>
import {
  computed, defineComponent, onBeforeUnmount, ref, watch,
} from 'vue';

import HistoryBlock from '@/components/HistoryBlock.vue';
import TabsContainer from '@/components/Containers/TabsContainer.vue';

import { useBus } from '@/composables/useBus';
import { useProfile } from '@/composables/useProfile';
import { useSocket } from '@/composables/useSocket';

import AddCredit from './AddCredit.vue';
import ItemHeader from './ItemHeader.vue';
import MerchantDetails from './MerchantDetails.vue';
import MerchantModify from './MerchantModify.vue';
import MerchantSettings from './MerchantSettings.vue';
import { getHistory, getMerchantInfo } from './api';

export default defineComponent({
  name: 'MerchantItem',
  components: {
    ItemHeader,
    TabsContainer,
    MerchantDetails,
    HistoryBlock,
    MerchantModify,
    MerchantSettings,
    AddCredit,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const selectedMerchant = ref(props.item);

    const status = computed(() => {
      if (selectedMerchant.value.status) {
        return selectedMerchant.value.status;
      }
      return props.item.status;
    });

    const shouldNotDisplayTabs = computed(() => !status.value || status.value === 'DELETED');

    const tabOption = computed(() => {
      const disabled = status.value !== 'ACTIVE' || [selectedMerchant.value.plan, props.item.plan].includes('IO');
      let tooltipMessage = '';
      if (status.value === 'SUSPENDED') {
        tooltipMessage = 'Not available for suspended merchants';
      }
      if (status.value === 'INACTIVE') {
        tooltipMessage = 'Not available for inactive merchants';
      }
      if (selectedMerchant.value.plan === 'IO') {
        tooltipMessage = 'Not available for IOs';
      }

      return {
        disabled,
        tooltipMessage,
        tooltipWidth: 'auto',
      };
    });

    const tabs = computed(() => [
      { text: 'Details', value: 'details' },
      { text: 'History', value: 'history' },
      { text: 'Modify ', value: 'modify', ...tabOption.value },
      { text: 'Add credit', value: 'credit', ...tabOption.value },
      { text: 'Settings ', value: 'settings', ...tabOption.value },
    ].filter((tab) => {
      if (shouldNotDisplayTabs.value && ['modify', 'settings', 'credit'].includes(tab.value)) {
        return null;
      }

      return tab;
    }).filter((el) => el));

    const currentTab = ref(tabs.value[0].value);

    const historyInfo = ref({});
    const isMerchantInfoLoading = ref(false);

    const merchantId = computed(() => props.item.id);

    const onGetMerchantInfo = () => getMerchantInfo(merchantId.value).then(({ data, isSuccess }) => {
      selectedMerchant.value = isSuccess ? data : {};
      isMerchantInfoLoading.value = false;
    });

    const { emitHandler } = useBus();
    emitHandler('get-merchant-info', () => {
      onGetMerchantInfo();
    });

    const currentSocket = ref(null);

    const voteListener = () => {
      if (currentSocket.value) {
        return;
      }

      if (selectedMerchant.value?.vote?.id) {
        const { addSocketListener, socket } = useSocket();
        currentSocket.value = socket;

        socket.on('connect', () => {
          socket.emit('room', selectedMerchant.value?.vote?.id);
        });

        addSocketListener('suspend-merchant-vote', () => {
          onGetMerchantInfo().then(() => {
            currentSocket.value = null;
          });
        });
      }
    };

    const onRefresh = () => {
      voteListener();
      isMerchantInfoLoading.value = true;

      onGetMerchantInfo();

      getHistory(merchantId.value).then(({ data, isSuccess }) => {
        historyInfo.value = isSuccess ? data : {};
        isMerchantInfoLoading.value = false;
      });
    };

    watch(computed(() => props.isOpen), async () => {
      if (props.isOpen) {
        onRefresh();
      }
    }, { immediate: true });

    const updateMerchantSettings = () => {
      emit('success', { shouldCloseModal: false });

      onGetMerchantInfo();
    };

    watch(selectedMerchant, () => {
      voteListener();
    });

    const { dateTimeFormat } = useProfile();

    onBeforeUnmount(() => {
      selectedMerchant.value = {};
    });

    return {
      currentTab,
      tabs,
      status,

      selectedMerchant,
      historyInfo,
      isMerchantInfoLoading,

      dateTimeFormat,

      updateMerchantSettings,
      onRefresh,
    };
  },
});
</script>
