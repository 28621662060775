<template>
  <div class="full-height">
    <div class="details-wrap">
      <div class="details">
        <div class="d-flex flex-column">
          <AppText variant="div" color="var(--color-939393)">
            Total Deposits
          </AppText>
          <CurrencyFormatter
            variant="div"
            size="20px"
            class="font-medium"
            mt="10px"
            :format="numberFormat"
            :name="info?.totalDeposits?.currency"
            :value="totalDeposits"
          />
        </div>
        <div class="d-flex flex-column">
          <AppText variant="div" color="var(--color-939393)">
            Total Invoiced
          </AppText>

          <CurrencyFormatter
            variant="div"
            size="20px"
            class="font-medium"
            mt="10px"
            :format="numberFormat"
            :name="info?.totalInvoiced?.currency"
            :value="totalInvoiced"
          />
        </div>
        <div v-if="plan" class="d-flex flex-column">
          <AppText variant="div" color="var(--color-939393)">
            Plan
          </AppText>
          <div class="d-flex align-items-center" style="margin-top: 10px;">
            <AppIcon :name="`plan-${plan.toLowerCase()}`" size="22px" class="mr-3" is-img-tag />
            <AppText size="15px" class="font-medium" :line-height="1">
              {{ plan }}
            </AppText>
          </div>
        </div>
        <div class="d-flex flex-column">
          <template v-if="status !== 'DELETED'">
            <AppText variant="div" color="var(--color-939393)">
              Registered
            </AppText>
            <DateFormatter :date="registeredDate" :format="dateFormat" mt="8px" />
          </template>
        </div>
        <div v-if="status !== 'DELETED' && email" class="d-flex flex-column">
          <AppText variant="div" color="var(--color-939393)">
            Email Address
          </AppText>
          <TooltipCopy is-icon-hidden>
            <AppText variant="div" mt="8px">
              {{ email }}
            </AppText>
          </TooltipCopy>
        </div>

        <template v-if="status === 'DELETED'">
          <div class="d-flex flex-column">
            <AppText variant="div" color="var(--color-939393)">
              Registered
            </AppText>
            <DateFormatter :date="registeredDate" :format="dateFormat" mt="8px" />
          </div>
          <div class="d-flex flex-column">
            <AppText variant="div" color="var(--color-939393)">
              Deleted
            </AppText>
            <DateFormatter :date="registeredDate" :format="dateFormat" mt="8px" />
          </div>
        </template>
      </div>
    </div>

    <Transition name="show">
      <div v-if="info.depositAddresses && info.depositAddresses.length > 0" class="addresses">
        <AppText
          type="uppercase"
          size="12px"
          color="var(--color-black-04)"
          variant="div"
          class="font-medium"
        >
          Deposit Addresses
        </AppText>
        <div class="crypto-addresses">
          <MerchantDepositAddresses :items="btcAddresses" />
          <MerchantDepositAddresses :items="ltcAddresses" />
        </div>
      </div>
    </Transition>

    <FSpacer />

    <div style="padding:0 40px 40px;">
      <Transition name="show">
        <MerchantItemActions
          v-if="!['DELETED', 'INACTIVE'].includes(status)"
          :info="info"
          :merchant-id="merchantId"
          :status="status"
          :is-loading="isLoading"
          @suspend="onSuspend"
          @unsuspend="onUnsuspend"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { currencyList } from '@/common/data';
import { makeFirstLetterUppercased } from '@/utils/functions';
import { useAdmin } from '@/composables/useAdmin';

import { suspendMerchant, unsuspendMerchant } from './api';
import MerchantDepositAddresses from './MerchantDepositAddresses.vue';
import MerchantItemActions from './MerchantItemActions.vue';

export default defineComponent({
  name: 'MerchantDetails',
  components: {
    MerchantItemActions,
    CurrencyFormatter,
    DateFormatter,
    TooltipCopy,
    MerchantDepositAddresses,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    preloadedInfo: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    merchantId: {
      type: String,
      default: '',
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const totalDeposits = computed(() => props.info?.totalDeposits?.amountUsd || props.preloadedInfo.balanceUsd);
    const totalDepositsSign = computed(() => currencyList.find((el) => el.short === props.info?.totalDeposits?.currency)?.symbol);

    const totalInvoiced = computed(() => props.info?.totalInvoiced?.amountUsd || props.preloadedInfo.totalInvoiced?.admounUsd);
    const totalInvoicedSign = computed(() => currencyList.find((el) => el.short === props.info?.totalInvoiced?.currency)?.symbol);

    const plan = computed(() => {
      const merchantPlan = props.info.plan || props.preloadedInfo.plan;
      return merchantPlan === 'IO' ? merchantPlan : makeFirstLetterUppercased(merchantPlan);
    });
    const registeredDate = computed(() => props.info.registered || props.preloadedInfo.createdAt);
    const deletedDate = computed(() => props.info.deletedAt || props.preloadedInfo.deletedAt);
    const email = computed(() => props.info.email || props.preloadedInfo.email);

    const processResponse = (isSuccess) => {
      if (isSuccess) {
        emit('success');
      }
    };

    const onSuspend = async () => {
      const { isSuccess } = await suspendMerchant({
        merchantId: props.merchantId,
      });

      processResponse(isSuccess);
    };

    const onUnsuspend = async () => {
      const { isSuccess } = await unsuspendMerchant({ merchantId: props.merchantId });

      processResponse(isSuccess);
    };

    const { dateFormat, numberFormat } = useAdmin();

    const btcAddresses = computed(() => props.info?.depositAddresses?.filter((el) => el.currency === 'BTC'));
    const ltcAddresses = computed(() => props.info?.depositAddresses?.filter((el) => el.currency === 'LTC'));

    const status = computed(() => props.info?.status || props.preloadedInfo?.status);

    return {
      totalDeposits,
      totalDepositsSign,
      totalInvoiced,
      totalInvoicedSign,
      plan,
      email,
      status,
      registeredDate,
      deletedDate,

      dateFormat,
      numberFormat,

      onSuspend,
      onUnsuspend,

      btcAddresses,
      ltcAddresses,
    };
  },
});
</script>

<style scoped lang="scss">
.details-wrap {
  background: var(--color-F5F5F5);
  padding: 30px 40px;
  min-height: 150px;

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px 80px;
  }
}

.addresses {
  padding: 30px 40px;
}

.crypto-addresses {
  margin-top: 10px;
  background: var(--color-white);
  border: 1px solid var(--color-black-007);
  border-radius: 8px;

  & > div:not(:last-child) {
    border-bottom: 1px solid var(--color-black-007);
  }
}
</style>
