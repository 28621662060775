<template>
  <div style="padding: 30px 40px 120px;">
    <div class="setting-item">
      <AppIcon name="users-stroke" size="18px" :opacity="0.4" is-img-tag />
      <AppText variant="div" class="setting-title" ml="15px">
        Client Limit
      </AppText>
      <div class="d-flex align-items-center">
        <FSelect
          v-model="clientDailyLimit"
          :options="dailyOptions"
          no-margin
          size="small"
          has-arrow
          @change="onLimitChange"
        />
        <AppText color="var(--color-999999)" variant="div" ml="12px" mr="36px">
          Daily
        </AppText>
        <FSelect
          v-model="clientLimit"
          :options="totalOptions"
          no-margin
          size="small"
          has-arrow
          @change="onLimitChange"
        />
        <AppText color="var(--color-999999)" variant="div" ml="12px">
          Total
        </AppText>
      </div>
    </div>
    <div class="setting-item">
      <AppIcon name="doc-bill-invoice" size="18px" :opacity="0.4" is-img-tag />
      <AppText variant="div" class="setting-title" ml="15px">
        Subscription Limit
      </AppText>
      <div class="d-flex align-items-center">
        <FSelect
          v-model="subscriptionDailyLimit"
          :options="dailyOptions"
          no-margin
          size="small"
          has-arrow
          @change="onLimitChange"
        />
        <AppText color="var(--color-999999)" variant="div" ml="12px" mr="36px">
          Daily
        </AppText>
        <FSelect
          v-model="subscriptionLimit"
          :options="totalOptions"
          no-margin
          size="small"
          has-arrow
          @change="onLimitChange"
        />
        <AppText color="var(--color-999999)" variant="div" ml="12px">
          Total
        </AppText>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import { changeLimits } from './api';

export default defineComponent({
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    merchantId: {
      type: String,
      default: '',
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const dailyOptions = [
      { text: '0', value: 0 },
      { text: '10', value: 10 },
      { text: '100', value: 100 },
      { text: 'Unlimited', value: 'unlimited' },
    ];

    const totalOptions = [
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '250', value: 250 },
      { text: '500', value: 500 },
      { text: '1000', value: 1000 },
      { text: 'Unlimited', value: 'unlimited' },
    ];

    const clientDailyLimit = ref(props.info?.maxDailyClient);
    const clientLimit = ref(props.info?.clientLimit);

    const subscriptionDailyLimit = ref(props.info?.maxDailyClientSubscription);
    const subscriptionLimit = ref(props.info?.subscriptionLimit);

    const onLimitChange = () => {
      setTimeout(async () => {
        const { isSuccess } = await changeLimits({
          merchantId: props.merchantId,
          data: {
            total: {
              maxClientsLimit: clientLimit.value,
              maxSubscriptionsLimit: subscriptionLimit.value,
            },
            daily: {
              maxClientsLimit: clientDailyLimit.value,
              maxSubscriptionsLimit: subscriptionDailyLimit.value,
            },
          },
        });

        if (isSuccess) {
          emit('success');
        }
      }, 0);
    };

    return {
      dailyOptions,
      totalOptions,

      clientDailyLimit,
      clientLimit,
      subscriptionDailyLimit,
      subscriptionLimit,

      onLimitChange,
    };
  },
});
</script>

<style scoped lang="scss">
.setting-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  :deep(.f-select--wrapper) {
    width: 110px;
  }
}
.setting-title {
  display: inline-block;
  width: 240px;
}
</style>
