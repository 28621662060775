<template>
  <div>
    <SuspendButton v-if="!vote" :label="`Vote to ${status}`" :type="status === 'ACTIVE' ? undefined : 'success'" @click="onVoteStart" />

    <div v-else class="vote">
      <transition name="show">
        <div v-if="intervalId" class="info">
          <CircleTimeLeft :percent="leftPercent">
            <AppIcon name="user-default" size="40px" is-img-tag />
          </CircleTimeLeft>
          <div>
            <AppText variant="div" class="font-medium" mb="4px">
              <template v-if="isVoteStarter">
                You voted to {{ status }}, awaiting consensus!
              </template>
              <template v-else>
                {{ voteStarter }} voted to {{ status }}
              </template>
            </AppText>
            <AppText variant="div" size="12px" :opacity="0.4">
              {{ leftTime.m }}:{{ leftTime.s }} left
            </AppText>
          </div>
        </div>
      </transition>

      <template v-if="admin.username && !isVoteStarter">
        <FButton
          type="plain"
          size="small"
          @click="onVote(true)"
        >
          Agree and {{ status }}
        </FButton>

        <FButton
          type="plain"
          size="small"
          @click="onVote(false)"
        >
          Disagree
        </FButton>
      </template>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onBeforeMount, onBeforeUnmount, ref, watch,
} from 'vue';
import dayjs from 'dayjs';

import CircleTimeLeft from '@/components/Progress/CircleTimeLeft.vue';

import {
  voteSuspendMerchant,
  voteSuspendMerchantStart,
} from '@/views/admin/AdminMerchants/MerchantItem/api';
import { emitter } from '@/composables/useBus';
import { useAdmin } from '@/composables/useAdmin';

import SuspendButton from './SuspendButton.vue';

const props = defineProps({
  status: {
    type: String,
    default: '',
  },
  voteInfo: {
    type: Object,
    default: () => {},
  },
  merchantId: {
    type: String,
    default: '',
  },
});

const vote = ref(props.voteInfo);
const intervalId = ref(null);

const { admin } = useAdmin();
const status = computed(() => (props.status === 'ACTIVE' ? 'suspend' : 'unsuspend'));

const voteStarter = computed(() => (vote.value?.for?.length ? vote.value?.for[0]?.username : null));
const isVoteStarter = computed(() => (voteStarter.value ? voteStarter.value === admin.value.username : true));

const expiredTime = computed(() => (vote.value?.expireTime ? dayjs(vote.value?.expireTime) : null));

const diff = ref(expiredTime.value?.diff(dayjs()));

const leftTime = ref(expiredTime.value?.subtract(dayjs()));
const leftPercent = computed(() => (diff.value / 1800000) * 100);
const isTimerActive = ref(false);

const convertSecondsToObject = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return {
    m: Math.abs(minutes),
    s: Math.abs(remainingSeconds) < 10 ? `0${Math.abs(remainingSeconds)}` : Math.abs(remainingSeconds),
  };
};

const startTimer = (ext) => {
  clearInterval(intervalId.value);
  leftTime.value = convertSecondsToObject(dayjs(ext).diff(dayjs(), 's'));

  intervalId.value = setInterval(() => {
    diff.value = dayjs(ext).diff(dayjs());
    if (diff.value >= 0) {
      leftTime.value = convertSecondsToObject(dayjs(ext).diff(dayjs(), 's'));
    } else {
      clearInterval(intervalId.value);
      vote.value = null;
    }
  }, 1000);
};

const onVoteStart = async () => {
  const { isSuccess, data } = await voteSuspendMerchantStart({ merchantId: props.merchantId, type: status.value.toUpperCase() });

  if (isSuccess) {
    vote.value = data;
    console.log(vote.value);
    startTimer(data.expireTime);
    emitter.emit('get-merchant-info');
    emitter.emit('update-merchants-page');
  }
};

const onVote = async (agree) => {
  const { isSuccess } = await voteSuspendMerchant({
    merchantId: props.merchantId,
    type: status.value.toUpperCase(),
    agree,
  });

  if (isSuccess) {
    emitter.emit('get-merchant-info');
  }
};

onBeforeMount(() => {
  if (expiredTime.value) {
    startTimer(expiredTime.value);
  }
});

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
});

watch(() => props.voteInfo, (newVal) => {
  vote.value = newVal;

  if (!isTimerActive.value && newVal?.expireTime) {
    startTimer(newVal.expireTime);
  }
});
</script>

<style lang="scss" scoped>
.vote {
  max-height: 50px;
  display: flex;
  align-items: center;

  .info {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
</style>
